








































































































































































































































.v-content{
  .nowrap_el{
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
    /*overflow: hidden;*/
    /*word-break: break-all;*/
    /*max-width: 1000px;*/
    /*display: block!important;*/
  }
  .el-table__body td.el-table__cell:nth-child(3){
    text-align: left!important;
  }
}

